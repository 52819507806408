<template>
    <div>
      <Footer></Footer>
        <div class="content">
          
          <span class="title">
            <!-- 第九届“大唐杯”全国大学生新一代信息通信技术大赛（南区）全国总决赛成功举办 -->
            “大唐杯”全国大学生新一代信息通信技术大赛简介
            <p class="titext">
              <span>“大唐杯”组委会</span>
              &emsp;&emsp;&emsp;&emsp;&emsp;
              <span>发布于2022-07-19</span>
            </p>
          </span>

          <div class="imgbox">
            <!-- <img  style="width:100%;height: 100%;" src="./Brief_img/img_A.jpg" alt="">  -->
          </div>

          <div class="contbox">
            <!-- <p class="text"> 2022年7月17日，第九届“大唐杯”全国大学生新一代信息通信技术大赛（南区）全国总决赛在武汉职业技术学院落下帷幕。</p> -->
            <div class="item_img">
              <img src="@/assets/images/1.jpg" />
            </div>
            <p class="text_t"> “大唐杯”全国大学生新一代信息通信技术大赛是由工业和信息化部人才交流中心、中国通信企业协会主办，中信科移动通信技术股份有限公司、北京市教委北京高校电子信息类专业群共同组织承办、北方工业大学、北京邮电大学等广大高校协办的全国性创新实践型竞赛。十年树木，百年树人，栉风沐雨，厚积薄发。如今，大唐杯已被教育部列入 “全国普通高校大学生竞赛排行榜”竞赛项目。服务于广大高校教师和学生群体，以培养高素质技术技能人才、协同高校学科建设、推动科技创新发展为目标。大赛依托中信科移动深厚的技术底蕴，将专业理论与工程实践相结合，已成为国内信息通信领域最具影响力的创新竞赛项目。</p>
              <div class="item_img">
              <img src="@/assets/images/2.jpg" />
            </div>
            <p class="text_t">大唐杯面向技术技能人才培养，面向高质量专业建设，面向科技创新发展，面向产业生态培育，聚焦工程人才发展。实现了专业与产业、教学与岗位、课程与生产、教材与项目四对齐，实现了竞赛环境、育人平台、教学体系、评估机制的人才培养模式四创新，实现了专业教学与产业工程、传统学科与新兴学科、育人机制与项目管理、人才培养与社会服务的校企合作四融合。大赛践行了“以赛促学、以赛促教、以赛促练、以赛促建”的办赛理念。</p>
            <div class="item_img">
              <img src="@/assets/images/3.jpg" />
            </div>
            <p class="text_t">大唐杯自2014年举办以来，参赛人数累计突破10万，覆盖全国400余所高校，培养双师型教师数千名。大赛设立省份选拔赛和全国总决赛两个赛程。面向双一流重点院校，普通本科院校，职业院校，一带一路国际合作院校分别设置研究生、本科、高职、一带一路、教师等不同赛道组别。实现了产业人才链培养全覆盖。大赛内容以信息通信技术为核心，融合大数据、人工智能等跨学科技术，引入智能网联汽车、智慧电网、物联网应用等垂直行业创新应用案例。实现了复合型人才培养综合发展。大赛以移动通信关键技术学习为基础，创建了产业认知、专业理论学习、虚拟仿真练习、真设备工程实践、工程应用创新、项目管理实践的人才培养体系。大唐杯促进了教育链、人才链、产业链和创新链的有效连接。</p>
            <div class="item_img">
              <img src="@/assets/images/4.jpg" />
            </div>
            <p class="text_t">大唐杯以追求卓越服务为宗旨，搭建起学唐在线和小唐人仿真教学云网，为广大师生提供全天候不间断线上与线下混合式教学服务。中信科移动培训中心将运营商网络建设的实际案例和产业核心技术进行总结转化，开发出与竞赛高度匹配的教学产品及人才培养解决方案。大唐杯不断发展壮大，开拓了校企工程实践育人的新途径，树立了新工科产教融合的新范例，搭载教育虚拟教研室的新快车，又将开启实践育人的新征程。</p>
            <div class="item_img">
              <img src="@/assets/images/5.jpg" />
            </div>
            <p class="text_t">一流设备、一流技术、一流课程、一流服务支撑大唐杯，以最强的国家队实力赢得信赖，携手广大高校共建电子通信教育产业联盟，撑起新时代信息通信人才培养的一片蓝天。</p>
            
            <div class="img_box">
            </div>
          </div>
        </div>
    <Bottom></Bottom>
    </div>
</template>
  
  <script>
  import Footer from '@/components/Footer/Footer.vue'
  import Bottom from '@/components/Bottom/Bottom.vue'
  import $ from "jquery";
  export default {
    name: 'Home',
    components: {
      Footer,
      Bottom
    },
    data(){
      return {

      }
    },
    methods:{
    },
    mounted(){

    },
    created(){

    },
  }
  </script>
  
  <style scoped lang="scss">

  @import './Brief.scss';
  </style>
  